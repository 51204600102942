import React, { FC, useState } from 'react';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { FeedType, HeaderNavigationCommonProps } from './models';
import { MobileDrawer } from 'wix-ui-tpa/MobileDrawer';
import { ActionsMenuLayout } from 'wix-ui-tpa/ActionsMenuLayout';
import { connect } from '../../../common/components/runtime-context';
import { getAllCategories } from '../../../common/selectors/categories-selectors';
import styles from './header-navigation-mobile.scss';
import { flowRight, isString } from 'lodash';
import { RootState } from '../../reducers/root-state';
import ArrowDownRight from 'wix-ui-icons-common/ArrowDownRight';
import { ChevronUpDownIcon } from '../../components/icons/chevron-up-down-icon';
import classNames from 'classnames';
import { generateHeaderNavigationItems } from './generate-header-navigation-items';
import { getActiveSelectorItem } from './store/header-navigation.selectors';

interface HeaderNavigationMobileProps {
  categories: any[];
  onCategoryChange: (slug: string) => void;
  activeStateName: string;
  navigateToMainPage: (id: FeedType, feedType: FeedType | null) => void;
}

const HeaderNavigationMobile: FC<HeaderNavigationMobileProps & HeaderNavigationCommonProps> = ({
  t,
  mainPageType,
  activeFeedType,
  categories,
  onCategoryChange,
  activeStateName,
  navigateToMainPage,
  isCategoriesPageVisible,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDrawer = () => setIsOpen(false);
  const navigationItems = generateHeaderNavigationItems({
    t,
    mainPageType,
    activeFeedType,
    isCategoriesPageVisible,
  });

  const activeItemName =
    navigationItems.find(x => x.id === activeStateName)?.title || activeStateName;

  return (
    <>
      <button
        type="button"
        className={styles.labelContainer}
        onClick={() => setIsOpen(true)}
        data-hook="header-tabs-select-trigger"
      >
        <span className={styles.label}>{activeItemName}</span>
        <ChevronUpDownIcon className={classNames(['forum-icon-fill', styles.labelIcon])} />
      </button>
      <div>
        <MobileDrawer isOpen={isOpen} onRequestClose={closeDrawer}>
          <ActionsMenuLayout className={styles.drawerContainer} data-hook="header-tabs-actions">
            {navigationItems.map(navigationItem => (
              <ActionsMenuLayout.Item
                key={navigationItem.feedType}
                onClick={() => {
                  navigateToMainPage(navigationItem.id, navigationItem.feedType);
                  closeDrawer();
                }}
                content={navigationItem.title}
              />
            ))}
            <ActionsMenuLayout.Divider />
            {categories.map(category => (
              <ActionsMenuLayout.Item
                key={category._id}
                onClick={() => {
                  onCategoryChange(category.slug);
                  closeDrawer();
                }}
                content={category.label}
                prefixIcon={isString(category.parentId) ? <ArrowDownRight /> : undefined}
              />
            ))}
          </ActionsMenuLayout>
        </MobileDrawer>
      </div>
    </>
  );
};

const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any) => {
  return {
    categories: getAllCategories(state),
    activeStateName: getActiveSelectorItem(state, ownProps.mainPageType),
    onCategoryChange: (slug: string) => actions.navigateWithinForum(`/${slug}`),
    navigateToMainPage: (id: FeedType, feedType: FeedType | null) =>
      actions.navigateToMainPage(id, feedType),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(HeaderNavigationMobile);
